@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
	font-family: 'KronaOne';
	font-display: swap;
	src: url(../fonts/KronaOne-Regular.ttf) format('truetype');
}

:root {
	--neonblue1: #0eb9f2;
	--neonblue2: #0eb9f2;
	// --darkblue: #415472;
	// --yellow: #fae601;
}

html {
	scroll-behavior: smooth;
	overflow-x: hidden !important;
}

body {
	font-family: Montserrat, sans-serif;
	background: #000;
	color: #cfc09f;
	font-size: 20px;
	background-size: auto 100vh;
	overflow: hidden;

	@media (max-width: 767px) {
		font-size: 16px;
		overflow-y: hidden;
	}
}

body::-webkit-scrollbar {
	width: 10px;
}

body::-webkit-scrollbar-track {
	background: #c6c3be;
}

body::-webkit-scrollbar-thumb {
	background: #474747;
}

.container-fluid {
	width: 100%;
	max-width: 1200px;

	@media (max-width: 767px) {
		padding: 0px 30px;
	}
}

.wallet-connect {
	display: block;
	border-radius: 12px;
	background-color: #ff0420;
	padding: 5px 25px;
	font-size: 17px;
	text-align: center;
	margin: 12px auto 2px;
	border-color: #fff;
}

.title {
	font-family: 'KronaOne', sans-serif;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 50px;
	font-size: 40px;
	color: #fff;
	// text-shadow: 0px 2px 59px #08a8e6;
	text-shadow: 0 0px 90px #b20600;

	@media (max-width: 767px) {
		font-size: 20px;
	}
}

.gold-text{
	color: #db9d3b;
	position: relative;
}

.nav-container {
	position: relative;
	z-index: 1;
	padding-top: 15px;
	.container {
		max-width: 1400px;
		width: 100%;
		.row {
			display: flex;
			align-items: center;
			.col-sm-6 {
				h3 {
					text-transform: uppercase;
					font-size: 40px;
					font-weight: 600;
					margin: 0;
					@media (max-width: 767px) {
						font-size: 25px;
					}
				}
				a {
					display: inline-block;
					text-decoration: none;
					border-radius: 10px;
					padding: 5px 30px;
					color: #fff;
					font-size: 20px;
					font-weight: 600;
					background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
						radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);

					@media (max-width: 767px) {
						font-size: 16px;
					}
				}
				@media (max-width: 767px) {
					&:first-child{
						flex: 1;
					}
				}
			}
		}
	}
}

.header-container {
	display: flex;
	align-items: center;
	position: relative;
	z-index: 3;
	overflow: hidden;
	padding-top: 0px;
	background-size: cover;
	background-position: center;
	flex-direction: column;
	justify-content: center;
	.container{
		height: 100%;
		padding-top: 0px;
		padding-bottom: 50px;
		.row{
			height: 100%;
			position: relative;
			z-index: 1;
			.col-sm-12{
				height: 100%;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				img{
					position: relative;
					width: 100%;
					max-width: 350px;
					border-radius: 50%;
					@media (max-width: 1400px) {
						max-width: 300px;
					}
					@media (max-width: 767px) {
						max-width: 250px;
					}
				}
				h3{
					text-transform: uppercase;
					font-size: 60px;
					font-weight: 600;
					@media (max-width: 767px) {
						font-size: 35px;
					}
				}
				p{
					font-size: 26px;
					font-weight: 500;
					color: #cfc09f;
					&.small{
						font-size: 18px;
						@media (max-width: 767px) {
							font-size: 14px;
						}
					}
					@media (max-width: 767px) {
						font-size: 18px;
					}
				}
			}
		}
		@media (max-width: 767px) {
			padding-bottom: 00px;
		}
	}
	@media (max-width: 767px) {
		padding-top: 30px;
	}
	.about-container {
		position: relative;
		margin-bottom: 50px;
		.container-fluid{
			max-width: 1400px;
			.row{
				margin: 0px;
				h3{
					text-align: center;
					text-transform: uppercase;
					font-size: 30px;
					font-weight: 600;
					margin-bottom: 30px;
				}
				.about-box{
					position: relative;
					z-index: 1;
					display: flex;
					flex-direction: column;
					align-items: center;
					border-radius: 15px;
					padding: 25px 15px;
					background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
							radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
					.col-sm-12{
						width: auto;
						h3{
							text-transform: uppercase;
							font-size: 20px;
							font-weight: 600;
							margin-bottom: 20px;
							margin-top: 0;
							color: #000;
							@media (max-width: 767px) {
								font-size: 16px;
							}
						}
						p{
							font-size: 16px;
							font-weight: 500;
							margin-bottom: 0;
							color: #000;
							&:not(:last-child){
								margin-bottom: 10px;
							}
							@media (max-width: 767px) {
								font-size: 14px;
							}
						}
					}
				}
				@media (max-width: 767px) {
					padding: 0px 15px;
				}
			}
			@media (max-width: 767px) {
				padding: 0px;
			}
		}
		@media (min-width: 1400px) {
			margin-bottom: 50px;
		}
		@media (max-width: 767px) {
			padding-top: 50px;
		}
	}
	@media (min-width: 1400px) {
		min-height: calc(100vh - 60px);
	}
}

#particles-js{
	position: absolute;
	width: 100%;
	opacity: 1;
	top: 0;
	left: 0;
}


.mint-container {
	position: relative;
	z-index: 3;
	padding-top: 10px;
	padding-bottom: 0px;
	background-image: url(../img/circuit_bg_repeat.png);
	background-size: 500px;
	background-position: center;

	.title-container{
		margin-bottom: 30px;
		position: relative;
		z-index: 2;
		.row {
			.col-sm-12{
				display: flex;
				justify-content: center;
				h3{
					text-transform: uppercase;
					font-size: 30px;
					font-weight: 600;
					margin-bottom: 30px;
					@media (max-width: 767px) {
						font-size: 25px;
						text-align: center;
						margin-bottom: 0px;
					}
				}
			}
		}
		@media (max-width: 767px) {
			margin-bottom: 0;
		}
	}
	
	.container-fluid {
		position: relative;
		padding: 00px 0px 0px;
		max-width: 1400px;
		z-index: 3;
		.row {
			margin: 0px;

			&.left-right {
				display: flex;
				// align-items: center;
				justify-content: space-evenly;
				margin: 0px;
				width: 100%;

				.col-sm-6 {
					text-align: center;
					width: 100%;
					max-width: 700px;
					.mint-box-container{
						padding: 20px 20px;
						height: 100%;
						// max-width: 600px;
						width: 100%;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						background: #000;
						border: 2px solid #cfc09f;
						box-shadow: 0px 0px 12px 0px #cfc09f, 0px 0px 12px 0px #ffecb3 inset;
						border-radius: 15px;
						transition: all 0.3s ease-in-out;
						position: relative;
						overflow: hidden;
						.details-container {
							h3{
								text-transform: uppercase;
								font-size: 24px;
								font-weight: 600;
								margin-bottom: 10px;
								@media (max-width: 767px) {
									font-size: 24px;
								}
							}
							img{
								width: 100%;
								max-width: 200px;
								margin-bottom: 30px;
								@media (max-width: 767px) {
									max-width: 150px;
								}
							}
							ul{
								margin-bottom: 50px;
								list-style-type: none;
								padding-left: 0px;
								&.silver{
									li{
										&:before{
											background-image: url('../img/lion_head_silver.png');
										}
									}
								}
								&.gold{
									li{
										&:before{
											background-image: url('../img/lion_head_gold.png');
										}
									}
								}
								li{
									font-size: 16px;
									padding-left: 30px;
									position: relative;
									&:before{
										content: "";
										width: 20px;
										height: 20px;
										position: absolute;
										left: 0;
										top: 3px;
										background-size: cover;
										background-position: center;
									}
									&:not(:last-child){
										padding-bottom: 15px;
									}
									@media (max-width: 767px) {
										font-size: 14px;
									}
								}
								@media (max-width: 767px) {
									padding-left: 20px;
								}
							}
						}
						.mint-box {
							.mint-box-info{
								p{
									font-size: 16px;
									&:not(:last-child){
										margin-bottom: 0px;
									}
								}
							}
							.mint-box-inner {
								position: relative;
								color: #cfc09f;
								border: 1px solid #4e4738;
								border-radius: 5px;
								background: rgba(233, 182, 79, 0.078);
								padding: 30px 30px;
								z-index: 1;

								p {
									font-size: 16px;
									color: #cfc09f;

									@media (max-width: 767px) {
										font-size: 13px;
									}
								}

								.info-container {
									display: flex;
									align-items: center;
									justify-content: space-between;
									margin-bottom: 10px;

									.img-container {
										width: 50px;

										img {
											width: 100%;
											border-radius: 10px;
										}

										@media (max-width: 767px) {
											width: 60px;
											margin-right: 15px;
										}
									}

									.inner-info-container {
										text-align: right;

										p {
											position: relative;
											margin-bottom: 0px;
											font-weight: 400;
											&:not(:last-child) {
												margin-bottom: 5px;
											}
										}

										h3 {
											margin-top: 0px;
											margin-bottom: 5px;
											position: relative;
											font-size: 20px;
											font-weight: 800;
										}
									}
								}

								.count {
									font-size: 24px;
									font-weight: 600;

									@media (max-width: 767px) {
										font-size: 18px;
									}
								}

								.mint-counter {
									position: relative;
									margin-bottom: 30px;
									display: flex;
									align-items: center;
									justify-content: space-between;
									background: rgba(224, 184, 85, 0.149);
									padding: 5px;
									border-radius: 10px;

									.counter {
										display: flex;
										align-items: center;
										justify-content: space-evenly;
										width: 100%;

										.btn {
											outline: none;
											width: 50px;
											height: 40px;
											font-size: 20px;
											font-weight: 900;
											color: #fff;
											background: transparent;
											border: none;
										}

										input {
											outline: 0;
											color: #fff;
											height: 40px;
											width: 50px;
											font-size: 18px;
											font-weight: 500;
											margin: 0px 5px;
											padding: 0px 5px;
											text-align: center;
											border: none;
											background: transparent;
										}
									}

									h3 {
										text-align: right;
										font-size: 20px;
										font-weight: 500;
										margin: 0px;

										@media (max-width: 767px) {
											font-size: 20px;
										}
									}
								}

								.total-container {
									position: relative;
									margin-bottom: 30px;
									display: flex;
									align-items: center;
									justify-content: space-between;

									p {
										position: relative;
										margin-bottom: 0px;
									}

									h3 {
										position: relative;
										font-size: 20px;
										font-weight: 800;
										margin: 0px;

										@media (max-width: 767px) {
											font-size: 20px;
										}
									}
								}

								.btn-mint {
									outline: 0;
									margin-top: 0px;
									border-radius: 10px;
									padding: 5px 40px;
									background: linear-gradient(45deg, #e3bd5c, #cf9728, #e3bd5c);
									color: #fff;
									font-weight: 500;
									font-size: 22px;
									letter-spacing: 1px;
									border: none;
									width: 100%;
									position: relative;
									overflow: hidden;
									
									span {
										color: #fff;
									}

									&:after {
										content: "";
										position: absolute;
										transition: all 0.3s ease-in-out;
										transition-timing-function: ease-in-out;
										top: -130%;
										left: -210%;
										width: 200%;
										height: 300%;
										opacity: 0;
										transform: skew(-40deg);
										background: rgba(255, 255, 255, 0.13);
										background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0.0) 100%);
									}

									&:hover:after {
										opacity: 1;
										left: 30%;
										transition-property: left, top, opacity;
										transition-duration: 0.7s, 0.7s, 0.15s;
									}
									@media (max-width: 767px) {
										padding: 10px 40px;
										font-size: 18px;
									}
								}

								@media (max-width: 767px) {
									border-radius: 10px;
								}
							}
						}
						&:after {
							content: "";
							position: absolute;
							top: -130%;
							left: -210%;
							width: 200%;
							height: 400%;
							opacity: 0;
							transform: skew(-40deg);
							background: rgba(255, 255, 255, 0.13);
							background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0.0) 100%);
						}

						&:hover:after {
							opacity: 0.7;
							left: 30%;
							transition-property: left, top, opacity;
							transition-duration: 0.7s, 0.7s, 0.15s;
							transition-timing-function: ease;
						}

						@media (max-width: 767px) {
							margin-bottom: 30px;
						}
					}
					&:first-child{
						padding-right: 100px;
						@media (max-width: 767px) {
							padding-right: 15px;
						}
					}
					&:last-child{
						padding-left: 100px;
						@media (max-width: 767px) {
							padding-left: 15px;
						}
					}
				}

				@media (max-width: 767px) {
					flex-direction: column;
				}
			}
		}

		@media (max-width: 767px) {
			min-height: 60vh;
			background-position: bottom center;
			padding: 50px 0px 0px;
		}
	}

	.join-club-container{
		margin-top: 20px;
		margin-bottom: 0px;
		padding: 70px 0px 50px;
		width: 100%;
		// background-color: #000;
		// background-image: url(../img/circuit_board_bg.png);
		background-size: cover;
		background-position: center;
		position: relative;
		z-index: 2;
		.row{
			position: relative;
			z-index: 2;
			.col-sm-12{
				text-align: center;
				a{
					display: inline-block;
					text-decoration: none;
					border-radius: 10px;
					padding: 15px 30px;
					color: #fff;
					width: 100%;
					max-width: 500px;
					font-size: 40px;
					font-weight: 600;
					margin-bottom: 20px;
					background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
							radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
					@media (max-width: 767px) {
						font-size: 26px;
					}
				}
				p{

				}
			}
		}
		&:before {
			content: "";
			width: 100%;
			height: 200px;
			position: absolute;
			bottom: 0%;
			left: 0%;
			background: linear-gradient(0deg, #000, transparent);
		}
		@media (max-width: 767px) {
			margin-bottom: 0px;
			padding: 20px 15px;
			margin-top: 30px;
		}
	}

	&:before {
		content: "";
		width: 100%;
		height: 300px;
		position: absolute;
		top: 0%;
		left: 0%;
		background: linear-gradient(0deg, transparent, #000);
	}

	&:after {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		bottom: 0%;
		left: 0%;
		z-index: 1;
		background: linear-gradient(90deg, #000 30%, rgba(0, 0, 0, 0.50));
	}

	@media (max-width: 767px) {
		padding: 10px 0px 0px;
	}
}

.faq-container {
	padding: 0px 0px 70px;
	position: relative;
	z-index: 4;
	background: #000;
	box-sizing: border-box;
	z-index: 1;

	.container-fluid {
		.row {
			position: relative;

			.col-sm-12 {
				.title-container {
					position: relative;

					h1 {
						margin-bottom: 70px;

						@media (max-width: 767px) {
							margin-bottom: 50px;
						}
					}
				}
			}

			.col-md-8 {
				.panel-group {
					z-index: 1;
					position: relative;

					.panel {
						margin-bottom: 50px;
						position: relative;
						background-color: #000;
						border: 2px solid #cfc09f;
						box-shadow: 0px 0px 12px 0px #cfc09f, 0px 0px 12px 0px #ffecb3 inset;
						border-radius: 15px;

						.panel-heading {
							color: #fff;
							background: transparent;
							border-radius: 0px;
							padding: 0;
							margin-left: -1px;
							margin-right: -1px;
							margin-top: -1px;
							margin-bottom: -1px;
							position: relative;
							z-index: 1;

							.panel-title {
								a {
									font-size: 18px;
									font-weight: 500;
									position: relative;
									display: block;
									text-decoration: none;
									padding: 30px 50px;

									&:before {
										content: "";
										width: 95%;
										height: 1px;
										position: absolute;
										bottom: 0px;
										left: 2.5%;
										background: #cfc09f;
									}

									.glyphicon {
										position: absolute;
										right: 30px;
										top: 30px;
										transition: all 0.3s ease;
										transform: rotate(180deg);
									}

									@media (max-width: 767px) {
										font-size: 16px;
									}

									&.collapsed {
										border-bottom: none;

										&:before {
											display: none;
										}

										.glyphicon {
											transform: rotate(0deg);
										}
									}
								}
							}
						}

						.panel-collapse {
							border-bottom-left-radius: 5px;
							border-bottom-right-radius: 5px;
							position: relative;
							z-index: 1;
							color: #fff;

							.panel-body {
								border: none;
								padding: 30px 50px;

								p {
									font-size: 16px;
									font-weight: 400;
									margin: 0px;

									a {
										color: #70bdff;

										&:hover {
											color: #1d94ff;
										}
									}

									@media (max-width: 767px) {
										font-size: 16px;
									}
								}
							}
						}

						@media (max-width: 767px) {
							&:last-child{
								margin-bottom: 30px;
							}
						}
					}
					@media (max-width: 767px) {
						margin-bottom: 0px;
					}
				}
			}
		}
		@media (max-width: 767px) {
			padding: 0px 15px;
		}
	}

	@media (max-width: 767px) {
		padding-bottom: 00px;
		padding-top: 20px;
	}
}

footer {
	position: relative;
	z-index: 9;

	.container-fluid {
		background-color: #000;
		max-width: unset;
		padding: 5px 15px;
		bottom: 0;
		border-top: 1px solid rgb(44, 44, 44);
		.row {
			display: flex;
			align-items: center;
			.social-links{
				padding-left: 0;
				.list-inline.links {
						text-align: left;
						margin: 0px;
				
						li {
							a {
								width: 35px;
								height: 35px;
								border-radius: 0px;
								padding: 0px;
								margin: 5px;
								background: #0000;
								line-height: 0;
								transition: all 0.3s ease;
								position: relative;
								overflow: hidden;
								
								& * {
									transition: all 0.3s ease;
								}
				
								img {
									transition: all 0.3s ease;
									width: 100%;
									position: relative;
									top: 0;
								}
				
								&:hover {
									img {
										top: -100%;
									}
								}
							}
				
							&.link {
								margin: 0px;
								width: 100%;

								p {
									font-size: 14px;

									a {
										text-decoration: none;
										font-weight: 500;
										overflow-wrap: break-word;
										color: #58c1e1;
										font-size: 14px;;
										&:hover {
											text-decoration: underline;
										}
									}
								}
							}
						}
						@media (max-width: 767px) {
							text-align: center;
							margin-bottom: 20px;
						}
					}
			}
			.rights {
				text-align: right;

				p {
					margin: 0px;
					font-size: 12px;
				}
				@media (max-width: 767px) {
					text-align: center;
				}
			}
			@media (max-width: 767px) {
				flex-flow: column-reverse;
				padding-top: 10px;
			}
		}
	}
}