.main-app {
  text-align: center;
  padding: 100px;
  background: #7b7b7b;
}

.box {
  border-radius: 0 0 6px 6px !important;
}

.cta-button {
  height: 45px;
  border: 0;
  width: auto;
  padding: 10px 55px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  margin-bottom: 25px;
}

.connect-wallet-button {
  background: #db9d3b;
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  margin-right: 15px;
  color: #ffffff;
  margin-top: 20px;
}

.silver-btn {
  background: #000;
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  margin-right: 15px;
  color: #ffffff;
  margin-top: 20px;
  border: 1px solid #ffecb3;
}

.mint-nft-button {
  background: -webkit-linear-gradient(left, #3262d6, #3140a0);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  margin-right: 15px;
  color: #ffffff;
  margin-top: 20px;
}

.os-button {
  margin: 20px auto;
  padding: 10px 20px;
  color: #fff;
  background-color: #1868b7;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}

.os-button a {
  color: #fff;
  text-decoration: none;
}

.banner-img {
  margin-bottom: 50px;
}

.banner-img img {
  max-height: 250px;
  max-width: 100%;
}

.header-container {
  padding-top: 30px;
}

.sub-text {
  font-size: 25px;
  color: #000000;
}

.summary-text {
  font-size: 16px;
  color: #000000;
}

.input-number {
  width: 80px;
  padding: 0 12px;
  vertical-align: top;
  text-align: center;
  outline: none;
}

.input-number,
.input-number-decrement,
.input-number-increment {
  border: 1px solid #ccc;
  height: 40px;
  user-select: none;
}

.input-number-decrement,
.input-number-increment {
  display: inline-block;
  width: 30px;
  line-height: 38px;
  background: #f1f1f1;
  color: #444;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
.input-number-decrement:active,
.input-number-increment:active {
  background: #ddd;
}

.input-number-decrement {
  border-right: none;
  border-radius: 4px 0 0 4px;
}

.input-number-increment {
  border-left: none;
  border-radius: 0 4px 4px 0;
}

.footer-text {
  font-weight: bold;
}

.navbar-item img {
  max-height: none !important;
}

.admin-bg-bolor {
  color: #000000 !important;
}

.vertical-center { 
  width:100%;
  height:100%;
}

.vertical-center .connect-wallet-button {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  font-weight: bold;
}

.vertical-center .connect-wallet-button:hover{
  color: #fff;
 }